html, body, #root {
  height: 100%;
  display: flex;
  flex-flow: column;
}

body {
  margin: 0;
  font-family: "Calibri", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6666666667;
  color: #333;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
