.nomargin {
  margin: 0;
}

.small-margin {
  margin: 0.5em !important;
}

.italic {
  font-style: italic;
}

.clickable {
  cursor: pointer;
}

.vertical-stretched {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.horizontal-stretched {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.align-right {
  text-align: right;
}

.flex {
  display: flex;
}

.space-around {
  justify-content: space-around;
}

/**
* Overwrite some Material UI styles to match IP Dynamics corporate design
*/

.MuiButton-root {
  font-family: "Sofia Pro", "Calibri", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 14px !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

a {
	color: #d22630;
	text-decoration: none;
}

a:hover, a:focus {
  color: #911a21;
  text-decoration: underline;
  outline: 0;
}

.numberAsTextField input::-webkit-outer-spin-button,
.numberAsTextField input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numberAsTextField input[type=number] {
  -moz-appearance: textfield;
}